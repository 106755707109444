import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { Grid, Typography, Link } from "@material-ui/core";
import {
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
} from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { AboutUsQuery } from "../../graphql-types";
import {
  Header,
  HomeHero,
  ImageWithStand,
  MarkdownDescription,
  useAboutUsStyles,
  useHomeStyles,
  Waves,
  Corners,
  Target,
  RoundedWhiteButton,
  ContactUsForm,
  Footer,
} from "../components";
import { toSuperScript } from "../utils";

interface Props {
  data: AboutUsQuery;
}

const AboutUs: React.FC<Props> = ({ data }: Props) => {
  const { strapiAboutUsContent } = data;

  const classes = useAboutUsStyles();
  const homeClasses = useHomeStyles();

  return (
    <div className={classes.about_us}>
      <Helmet title="About us">
        <meta name="description" content={strapiAboutUsContent.subtitle} />
        <meta name="image" content="/about-us-hero.png" />
        <meta property="og:url" content="/about-us" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={strapiAboutUsContent.subtitle}
        />
        <meta property="og:image" content="/about-us-hero.png" />
      </Helmet>

      <Header />

      <HomeHero
        img="/about-us-hero.svg"
        title={strapiAboutUsContent.title}
        subtitle={strapiAboutUsContent.subtitle}
        hideSignUp
        hideTabs
      />

      <div className={classes.content}>
        <div
          className={clsx(
            classes.venn_container,
            homeClasses.description_section
          )}
        >
          <img src="/venn-diagram.png" alt="suny venn diagram" />
        </div>

        <Grid container justify="center" id="about-us">
          <Grid item container xs={11} md={10} spacing={3}>
            <Grid item xs={12} className={classes.intro_container}>
              <Typography variant="h4">
                {strapiAboutUsContent.about_us_title}
              </Typography>
              <Typography variant="body1">
                <b>{strapiAboutUsContent.about_us_subtitle}</b>
              </Typography>
              <MarkdownDescription
                accentColor={"#000"}
                description={strapiAboutUsContent.about_us_maincontent}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          container
          justify="center"
          id="our-vision"
        >
          <Grid container item xs={11} md={10} spacing={3}>
            <Grid item xs={12} md={6}>
              <div className={classes.suny_description}>
                <div className="title">
                  <Typography variant="h3">
                    <b
                      dangerouslySetInnerHTML={{
                        __html: toSuperScript(
                          strapiAboutUsContent.suny_title_left
                        ),
                      }}
                    />
                  </Typography>
                  <Typography variant="h5">
                    <b>{strapiAboutUsContent.suny_title_right}</b>
                  </Typography>
                </div>
                <Typography variant="body1">
                  {strapiAboutUsContent.suny_intro}
                </Typography>
                <Typography variant="h5">
                  <b>{strapiAboutUsContent.suny_description_title}</b>
                </Typography>
                <Typography variant="body1">
                  <b>{strapiAboutUsContent.suny_description}</b>
                </Typography>
                <Typography variant="caption">
                  {strapiAboutUsContent.suny_caption}
                </Typography>
                <div className={classes.download_links}>
                  <a
                    href="https://play.google.com/store/apps"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src="/google-play-logo.png" alt="google play" />
                  </a>
                  <a
                    href="https://www.apple.com/ru/app-store/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src="/app-store-logo.png" alt="app store" />
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                className={classes.meet_suny_img}
                src="/meet-suny.svg"
                alt="meet suny"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          container
          justify="center"
        >
          <Grid container item xs={11} md={10} spacing={3}>
            <Grid
              className={clsx(homeClasses.order, "left")}
              item
              xs={12}
              md={6}
            >
              <ImageWithStand
                src="/ai-tech-for-good.png"
                standColor="#F4D525"
                adornment="waves"
                standPosition="right"
              />
            </Grid>
            <Grid
              className={clsx(homeClasses.order, "right")}
              item
              xs={12}
              md={6}
            >
              <div className={classes.ai_for_good_wrapper}>
                <img src="/AItechforgood.png" alt="AI Tech for good" />
              </div>
              <Typography variant="h5">
                <b>AI tech</b> <b className={classes.orange}>for good</b>
              </Typography>
              <Typography
                className={homeClasses.description_item}
                variant="body1"
              >
                <b>
                  Augmented Intelligence can help us humans fulfil our potential
                  and create better organisations - if we can keep pace with it
                  and leverage it sensibly!
                </b>
              </Typography>
              <Typography
                className={homeClasses.description_item}
                variant="body1"
              >
                <b>AI Tech for Good</b> members help each other understand and
                explore the challenges and opportunities being created by this
                rapidly evolving field.
              </Typography>
              <Typography
                className={homeClasses.description_item}
                variant="body1"
              >
                Share the AI tech for good vision? AITFG Members discuss how to
                improve current AI Tech to help humans fulfil their potential
                and create better organisations.
              </Typography>
              <Link
                className={clsx(
                  homeClasses.description_item,
                  homeClasses.description_link
                )}
                href="https://portal.flourish.zone/register"
              >
                Find out more
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          container
          justify="center"
        >
          <Grid container item xs={11} md={10} spacing={3}>
            <Grid item xs={12} md={4}>
              <div className={classes.achievement_item}>
                <Waves />
                <Typography variant="h5">
                  <b>
                    <>
                      {
                        strapiAboutUsContent.achievement_items[0]
                          .title_first_part
                      }{" "}
                      <span className={classes.dark_pink}>
                        {
                          strapiAboutUsContent.achievement_items[0]
                            .title_second_part
                        }{" "}
                      </span>
                      {
                        strapiAboutUsContent.achievement_items[0]
                          .title_third_part
                      }
                    </>
                  </b>
                </Typography>
                <Typography variant="body1">
                  {strapiAboutUsContent.achievement_items[0].description}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.achievement_item}>
                <Target />
                <Typography variant="h5">
                  <b>
                    <>
                      {
                        strapiAboutUsContent.achievement_items[1]
                          .title_first_part
                      }{" "}
                      <span className={classes.cyan}>
                        {
                          strapiAboutUsContent.achievement_items[1]
                            .title_second_part
                        }{" "}
                      </span>
                      {strapiAboutUsContent.achievement_items[1]
                        .title_third_part ?? ""}
                    </>
                  </b>
                </Typography>
                <Typography variant="body1">
                  {strapiAboutUsContent.achievement_items[1].description}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.achievement_item}>
                <Corners />
                <Typography variant="h5">
                  <b>
                    <>
                      {
                        strapiAboutUsContent.achievement_items[2]
                          .title_first_part
                      }{" "}
                      <span className={classes.green}>
                        {
                          strapiAboutUsContent.achievement_items[2]
                            .title_second_part
                        }{" "}
                      </span>
                      {
                        strapiAboutUsContent.achievement_items[2]
                          .title_third_part
                      }
                    </>
                  </b>
                </Typography>
                <Typography variant="body1">
                  {strapiAboutUsContent.achievement_items[2].description}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="our-team"
        >
          <Grid item xs={11} md={10} xl={8}>
            <div className={homeClasses.centered_title}>
              <Typography variant="h4">
                <b>{strapiAboutUsContent.about_us_title}</b>
              </Typography>
              <Typography variant="body2">
                {strapiAboutUsContent.about_us_subtitle}
              </Typography>
            </div>
            <Grid
              className={classes.team_members_container}
              container
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              {strapiAboutUsContent.team_members.map(member => (
                <Grid item xs={10} sm={8} md={2} key={member.id}>
                  <div className={classes.team_member_item}>
                    <img
                      src={member.avatar.childImageSharp.original.src}
                      alt={member.full_name}
                    />
                    <Typography variant="subtitle1">
                      <b>{member.full_name}</b>
                    </Typography>
                    <Typography variant="body1">{member.role}</Typography>
                    <Typography variant="body2">{member.bio}</Typography>
                    <div className="social_container">
                      {member.linkedin_url && (
                        <a
                          href={member.linkedin_url}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <LinkedInIcon />
                        </a>
                      )}
                      {member.twitter_url && (
                        <a
                          href={member.twitter_url}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <TwitterIcon />
                        </a>
                      )}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <div
          className={clsx(
            classes.sign_up_container,
            homeClasses.description_section
          )}
        >
          <img
            src={
              strapiAboutUsContent.sign_up_image.childImageSharp.original.src
            }
            alt={strapiAboutUsContent.sign_up_title}
          />
          <div className="description">
            <Typography variant="h4">
              <b>{strapiAboutUsContent.sign_up_title}</b>
            </Typography>
            <Typography variant="subtitle1">
              {strapiAboutUsContent.sign_up_subtitle}
            </Typography>
            <RoundedWhiteButton
              variant="contained"
              size="large"
              href="https://portal.flourish.zone/register"
              component={OutboundLink}
            >
              {strapiAboutUsContent.call_to_action}
            </RoundedWhiteButton>
          </div>
        </div>

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="contact-us"
        >
          <Grid item xs={11} sm={10} md={6} lg={5} xl={4}>
            <ContactUsForm />
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;

export const query = graphql`
  query AboutUs {
    strapiAboutUsContent {
      title
      subtitle
      call_to_action
      about_us_title
      about_us_subtitle
      about_us_maincontent
      suny_title_left
      suny_title_right
      suny_intro
      suny_description_title
      suny_description
      suny_caption
      achievement_items {
        title_first_part
        title_second_part
        title_third_part
        id
        description
      }
      team_members {
        id
        full_name
        role
        bio
        twitter_url
        linkedin_url
        avatar {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      sign_up_image {
        childImageSharp {
          original {
            src
          }
        }
      }
      sign_up_title
      sign_up_subtitle
    }
  }
`;
